// Banner
$(function () {
  function initBannerCarousel() {
    const bannerList = window.banner;
    const indexContainer = $("#index-banner.swiper-wrapper");
    const isLoggedIn = $(".before-login.hide").length !== 0;

    bannerList &&
      bannerList.length !== 0 &&
      bannerList.forEach(function (item) {
        const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;

        if (imageUrl && redirectGroup !== 1 && (redirectGroup !== 4 || redirectUrl !== "/faq")) {
          /**
           * Redirect Group Option
           * 0 - Promo
           * 1 - Join Us
           * 2 - Lottery
           * 3 - Product
           * 4 - Others inner page (Loyalty, Customer Service)
           * 5 - 自定义
           */
          const banner = $(`
            <div class="swiper-slide" data-swiper-autoplay="5000">
                <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}"/>
                <div class="img-bg" style="background-image: url(${imageUrl})"></div>
            </div>
          `);

          banner.click(() =>
            window.initBannerCarouselRedirection({
              redirectUrl,
              redirectGroup,
              redirectPage,
              isLoggedIn,
            })
          );

          /**
           * index append 首页type banner, promo append 优惠活动type banner
           * 轮播图类型 bannerType
           * 0 - 首页
           * 1 - 优惠活动
           * 2 - 其他
           */
          if (bannerType === 0) {
            indexContainer.append(banner);
          }
        }
      });

    if (bannerList && bannerList.length) {
      var mySwiper = new Swiper(".swiper-container.banner", {
        speed: 400,
        autoplay: {
          delay: 5000,
        },
        loop: true,
        // If we need pagination
        pagination: {
          el: ".swiper-pagination.banner",
        },
      });
    }
  }

  if (Pace) {
    Pace.on("done", initBannerCarousel);
  } else {
    $(window).load(initBannerCarousel);
  }
});

$(document).ready(function () {
  $(function () {
    if (window.promo) {
      renderPromo(window.promo);
    } else {
      Object.defineProperty(window, "promo", {
        set: function (data) {
          renderPromo(data);
        },
      });
    }

    function renderPromo(promoData) {
      if (Array.isArray(promoData)) {
        const promoWrapper = $(".promo-wrapper");
        promoData.forEach(function (value) {
          const { requestToJoin, firstDepositPromoJoinMsg, promoId, type, imagePath, title, content } = value;

          const promoItem = $(`<div class="main_box" data-type="${type}"></div>`);
          const valueContainer = $(`<div class="text nr" style="display: none;">${content}</div>`);
          const subContentItemFooter = $(`<div class="sub-content-item-footer"></div>`);

          if (requestToJoin && firstDepositPromoJoinMsg) {
            subContentItemFooter.append(`
              <div class="sub-content-item-footer--info">
                ${firstDepositPromoJoinMsg}
              </div>
            `);
          }

          if (requestToJoin && !firstDepositPromoJoinMsg) {
            subContentItemFooter.append(`
              <button
                class="sub-content-item-footer--register-promo "
                data-id="${promoId}"
              >
                申请参与
              </button>
            `);
          }

          valueContainer.append(subContentItemFooter);

          promoItem.append(
            imagePath
              ? `
              <div class="icon sub-content-item-header">
                <img src="${imagePath}" />
              </div>
            `
              : `
              <div class="sub-content-item-header">
                <h3>${title}</h3>
              </div>
            `
          );

          promoItem.append(valueContainer);

          promoWrapper.append(promoItem);
        });

        $(document).on("click", ".main_box .sub-content-item-header", function (e) {
          e.preventDefault();
          var mainbox = $(this).closest(".main_box");
          if (mainbox.hasClass("on")) {
            mainbox.removeClass("on");
            mainbox.children(".bt").show();
            mainbox.children(".nr").slideUp();
          } else {
            mainbox.addClass("on");
            mainbox.children(".bt").hide();
            mainbox.children(".nr").slideDown();
          }
        });

        $(document).on("click", ".sub-content-item-footer--register-promo", function (e) {
          e.preventDefault();
          var promoId = $(this).data("id");
          api.registerPromo(promoId);
        });
      }
    }
  });
});
